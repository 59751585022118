// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bookmark-js": () => import("./../src/pages/bookmark.js" /* webpackChunkName: "component---src-pages-bookmark-js" */),
  "component---src-pages-crowley-js": () => import("./../src/pages/crowley.js" /* webpackChunkName: "component---src-pages-crowley-js" */),
  "component---src-pages-demo-project-js": () => import("./../src/pages/demo_project.js" /* webpackChunkName: "component---src-pages-demo-project-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-phone-js": () => import("./../src/pages/phone.js" /* webpackChunkName: "component---src-pages-phone-js" */),
  "component---src-pages-phone-project-js": () => import("./../src/pages/phone_project.js" /* webpackChunkName: "component---src-pages-phone-project-js" */),
  "component---src-pages-resume-js": () => import("./../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */)
}

